<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <!-- Brand logo-->
        <b-link class="brand-logo d-flex" to="/">
          <b-img :src="require('@/assets/images/logo/logo.png')" alt="logo" />
          <h2 class="brand-text text-primary ml-1 mb-0 align-self-center">
            {{ appTitle }}
          </h2>
        </b-link>
        <!-- /Brand logo-->

        <b-card-title class="mb-1"> Forgot Password?</b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your
          password
        </b-card-text>

        <!-- form -->
        <validation-observer ref="forgotPasswordValidation">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group label="Email" label-for="forgot-password-email">
              <validation-provider
                #default="{ errors }"
                name="email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- loading button -->
            <template v-if="isLoading">
              <b-button variant="primary" block disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>
            <!-- submit button -->
            <template v-else>
              <b-button variant="primary" block type="submit">
                Send reset link
              </b-button>
            </template>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BImg,
  BSpinner,
} from "bootstrap-vue";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "ForgotPasswordView",
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BSpinner,
  },
  data() {
    return {
      appTitle: process.env.VUE_APP_TITLE,
      email: "",
      isLoading: false,
    };
  },
  methods: {
    validationForm: function () {
      this.$refs.forgotPasswordValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;

            const res = await this.$api.post("/api/forgot-password", {
              email: this.email,
            });
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: res?.data?.message,
              },
            });
          } catch (error) {
            this.isLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.forgotPasswordValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
